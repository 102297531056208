import {NavStyle} from '../../../shared/constants/AppEnums';
import ConnectHeaderMini from './ConnectUserHeader';

interface LayoutsProps {
  [x: string]: any;
}

const Layouts: LayoutsProps = {
  [NavStyle.CONNECT_HEADER_MINI]: ConnectHeaderMini,
};
export default Layouts;
