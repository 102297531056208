import {AuthUser} from '../../types/models/AuthUser';
//import {AuthType} from './AppEnums';

export const authRole = {
  admin: ['admin', 'supervisor'],
  supervisor: ['supervisor'],
};

export const defaultUser: AuthUser = {
  email: 'demo@example.com',
  username: 'tester@testing.com',
  role: 'admin',
  groups: authRole.admin,
};
export const initialUrl = '/'; // this url will open after login
