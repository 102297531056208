import React, {ReactNode, useContext, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {matchRoutes} from 'react-router-config';
import qs from 'qs';
import AppContext from './AppContext';
//import {useAuthToken} from './AppHooks';
//import {Loader} from '../index';
import {checkPermission} from './Utils';
import {initialUrl} from '../../shared/constants/AppConst';
import AppContextPropsType from '../../types/AppContextPropsType';
import {NavStyle, ThemeMode, ThemeStyle} from '../../shared/constants/AppEnums';

interface AuthRoutesProps {
  children: ReactNode;
}

const AuthRoutes: React.FC<AuthRoutesProps> = ({children}) => {
  const {user} = useContext(AppContext);
  const {pathname, search} = useLocation();
  const history = useHistory();
  const {
    routes,
    changeNavStyle,
    updateThemeStyle,
    initialPath,
    setInitialPath,
    updateThemeMode,
  } = useContext<AppContextPropsType>(AppContext);

  //const [loading, user] = useAuthToken();
  const currentRoute = matchRoutes(routes, pathname)[0].route;
  let isPermitted = checkPermission(currentRoute.auth, user ? user.role : null);
  useEffect(() => {
    function setInitPath() {
      if (
        initialPath === '/' &&
        [
          '/signin',
          '/signup',
          '/confirm-signup',
          '/reset-password',
          '/error-pages/error-404',
          '/forget-password',
        ].indexOf(pathname) === -1
      ) {
        if (isPermitted) {
          setInitialPath(pathname);
        } else {
          setInitialPath(undefined);
        }
      }
    }

    setInitPath();
  }, [isPermitted, setInitialPath, initialPath, pathname]);

  useEffect(() => {
    function handleQueryParams() {
      const query = qs.parse(search.split('?')[1]);
      if (query.layout) {
        changeNavStyle(query.layout as NavStyle);
      }
      if (query.mode) {
        updateThemeMode(query.mode as ThemeMode);
      }
      if (query.style) {
        updateThemeStyle!(query.style as ThemeStyle);
      }
    }

    if (search) {
      handleQueryParams();
    }
  }, [changeNavStyle, updateThemeMode, updateThemeStyle, search]);

  useEffect(() => {
    if (!user && !isPermitted) {
      history.push('/signin'); // allowed route
    } else if (user && !isPermitted) {
      history.push('/error-pages/error-401'); // Not found
    } else if (user && isPermitted) {
      if (pathname === '/') {
        if (user.role === 'admin') {
          history.push(`${initialUrl}admin`);
        } else {
          history.push(`${initialUrl}supervisor`);
        }
      } else {
        // @ts-ignore
        if (initialPath && initialUrl !== initialPath && initialPath !== '/') {
          history.push(initialPath);
        }
      }
    }
  }, [user, initialPath, isPermitted, pathname, history]);

  return <>{children}</>;
};

export default AuthRoutes;
