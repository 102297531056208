import React from 'react';

export const administratorPagesConfig = [
  {
    auth: ['admin'],
    routes: [
      {
        path: '/calender-management/holidays',
        component: React.lazy(() => import('./Pages/HolidaysPage')),
      },
    ],
  },
  {
    auth: ['admin'],
    routes: [
      {
        path: '/calender-management/closures',
        component: React.lazy(() => import('./Pages/ClosuresPage')),
      },
    ],
  },
  {
    auth: ['admin'],
    routes: [
      {
        path: '/calender-management/hours',
        component: React.lazy(() => import('./Pages/HoursPage')),
      },
    ],
  },
  {
    auth: ['admin'],
    routes: [
      {
        path: '/contact-flows',
        component: React.lazy(() => import('./Pages/ConfigurationSetPage')),
      },
    ],
  },
  {
    auth: ['admin'],
    routes: [
      {
        path: '/admin',
        component: React.lazy(() => import('./Pages/Landing')),
      },
    ],
  },
];
