import React, {useState, useContext} from 'react';
import {Auth} from 'aws-amplify';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Box from '@material-ui/core/Box';
import useStyles from './AppHeader.style';
import HeaderMessages from '../../HeaderMessages';
import Notifications from '../../Notifications';
import AppLogo from '../../../../shared/components/AppLogo';
import Typography from '@material-ui/core/Typography';
import ExitToApp from '@material-ui/icons/ExitToApp';
import clsx from 'clsx';
import {
  toggleNavCollapsed,
  useLayoutActionsContext,
} from '../LayoutContextProvider';
import AppContextPropsType from '../../../../types/AppContextPropsType';
import AppContext from '../../../utility/AppContext';
import {useHistory} from 'react-router-dom';
interface AppHeaderProps {}

const AppHeader: React.FC<AppHeaderProps> = () => {
  const classes = useStyles();
  const dispatch = useLayoutActionsContext()!;
  const history = useHistory();
  const {user} = useContext<AppContextPropsType>(AppContext);
  const [
    mobileMoreAnchorEl,
    setMobileMoreAnchorEl,
  ] = useState<null | HTMLElement>(null);

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMobileMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setMobileMoreAnchorEl(event.currentTarget);
  }
  const signOutButton = async function signOut(): Promise<void> {
    try {
      await Auth.signOut();
      history.push('/');
    } catch (error) {
      console.warn('error signing out: ', error);
    }
  };


  return (
    <>
      <AppBar color='inherit' className={clsx(classes.appBar, 'app-bar')}>
        <Toolbar className={classes.appToolbar}>
          <AppLogo />
          <Box className={classes.grow} />
          <Box className={classes.sectionDesktop}>
            <Typography className={classes.userName}>{user?.email}</Typography>
            <IconButton
              className={classes.logoutIcon}
              color='inherit'
              onClick={signOutButton}
              aria-label='userName'>
              <ExitToApp />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};
export default AppHeader;
