import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import AppLayout from '@vf/core/AppLayout';
import AuthRoutes from '@vf/utility/AuthRoutes';
import ContextProvider from '@vf/utility/ContextProvider';
import CssBaseline from '@material-ui/core/CssBaseline';
import InfoViewContextProvider from '@vf/core/InfoView/InfoViewContext';
import VFThemeProvider from '@vf/utility/VFThemeProvider';
import VFStyleProvider from '@vf/utility/VFStyleProvider';
import ApiContextProvider from '@vf/utility/ApiContextProvider/ApiContextProvider';
const App = () => (
  <ContextProvider>
    <ApiContextProvider>
      <InfoViewContextProvider>
        <VFThemeProvider>
          <VFStyleProvider>
            <BrowserRouter>
              <AuthRoutes>
                <CssBaseline />
                <AppLayout />
              </AuthRoutes>
            </BrowserRouter>
          </VFStyleProvider>
        </VFThemeProvider>
      </InfoViewContextProvider>
    </ApiContextProvider>
  </ContextProvider>
);

export default App;
