export interface NavItemProps {
  id: string;
  messageId: string;
  title: string;
  icon?: string;
  exact?: boolean;
  url?: string;
  type?: string;
  count?: number;
  color?: string;
  auth?: string[];
  children?: NavItemProps[] | NavItemProps;
}

const routesConfig: NavItemProps[] = [
  {
    id: 'calender-management',
    auth: ['admin'],
    title: 'Calender Management',
    messageId: 'Calender Management',
    type: 'collapse',
    icon: 'event',
    children: [
      {
        id: 'holiday-management',
        title: 'Holiday Management',
        messageId: 'Holidays',
        type: 'item',
        url: '/calender-management/holidays',
      },
      {
        id: 'hours-management',
        title: 'Hours Management',
        messageId: 'Hours',
        type: 'item',
        url: '/calender-management/hours',
      },
      {
        id: 'closures-management',
        title: 'Closures Management',
        messageId: 'Closures',
        type: 'item',
        url: '/calender-management/closures',
      },
    ],
  },
  {
    id: 'contact-flow-management',
    auth: ['admin'],
    title: 'Configuration Set',
    messageId: 'Configuration Set',
    type: 'item',
    url: '/contact-flows',
    icon: 'settings',
  },
];
export default routesConfig;
