import {getLogger} from '@vf/services/LoggingService';
import {IConnectApi} from 'types/api/api-interfaces';
import {ConnectPrompt} from 'types/api/api-models';
import {ApiClientBase} from './ApiClientBase';

export class ConnectApiClient extends ApiClientBase implements IConnectApi {
  constructor(apiUrl: string, useAuth: boolean) {
    super(apiUrl, getLogger('ConnectApiClient'), useAuth);
  }

  async listPrompts(): Promise<ConnectPrompt[]> {
    const result = await this.get('/api/connect/listPrompts');
    return await result.json();
  }
}
