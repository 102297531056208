export class Logger {
  private logTrace = true;

  constructor(private name: string) {}

  debug(message: string, ...properties: any[]) {
    if (this.logTrace) {
      console.debug(`${this.name}: ${message}`, properties);
    }
  }

  error(message: string, ...properties: any[]) {
    console.error(`${this.name}: ${message}`, properties);
  }
}

const loggers: {[name: string]: Logger} = {};

export const getLogger = (serviceName: string): Logger => {
  if (!loggers[serviceName]) {
    loggers[serviceName] = new Logger(serviceName);
  }
  return loggers[serviceName];
};
