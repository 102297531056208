import {getLogger} from '@vf/services/LoggingService';
import {HelperApi} from 'types/api/api-interfaces';
import {ConnectQueue} from 'types/api/api-models';
import {ApiClientBase} from './ApiClientBase';

export class HelpersApiClient extends ApiClientBase implements HelperApi {
  constructor(apiUrl: string, useAuth: boolean) {
    super(apiUrl, getLogger('HelpersApiClient'), useAuth);
  }

  async getAllQueues(): Promise<ConnectQueue[]> {
    const result = await this.get('/api/helpers/queues');
    return await result.json();
  }

  async getAllTimezones(): Promise<string[]> {
    const result = await this.get('/api/helpers/timezones');
    return await result.json();
  }
}
