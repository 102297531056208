import React from 'react';
import {create} from 'jss';
import {jssPreset, StylesProvider} from '@material-ui/core/styles';
// Configure JSS
const jss = create({plugins: [...jssPreset().plugins]});

const VFStyleProvider: React.FC<React.ReactNode> = (props) => {
  return <StylesProvider jss={jss}>{props.children}</StylesProvider>;
};
export default VFStyleProvider;
