import {ApiClientBase} from './ApiClientBase';
import {getLogger} from '@vf/services/LoggingService';
import {IContactFlowsApi} from 'types/api/api-interfaces';

import {
  ContactFlowVm,
  GetPromptAudioRequest,
  PromptVm,
  AttributeVm,
  ConfigurationSetIE,
} from 'types/api/api-models';

export class ContactFlowsApiClient
  extends ApiClientBase
  implements IContactFlowsApi {
  constructor(apiUrl: string, useAuth: boolean) {
    super(apiUrl, getLogger('ContactFlowsApiClient'), useAuth);
  }

  async deleteAttribute(flowId: string, attrId: string): Promise<void> {
    await this.delete(`/api/contactFlows/${flowId}/attributes/${attrId}`);
  }

  async deleteContactFlow(flowId: string): Promise<void> {
    await this.delete(`/api/contactFlows/${flowId}`);
  }

  async deletePrompt(flowId: string, promptId: string): Promise<void> {
    await this.delete(`/api/contactFlows/${flowId}/prompts/${promptId}`);
  }

  async getContactFlows(): Promise<any[]> {
    const result = await this.get('/api/contactFlows');
    return await result.json();
  }

  async getContactFlow(flowId: string): Promise<ContactFlowVm> {
    const result = await this.get(`/api/contactFlows/${flowId}`);
    return await result.json();
  }

  async getPromptAudio(
    body: GetPromptAudioRequest,
  ): Promise<{AudioStream: string}> {
    const result = await this.get('/api/contactFlows/getPromptAudio');
    return await result.json();
  }

  async createAttribute(
    attr: AttributeVm,
    contactFlow: string,
  ): Promise<AttributeVm> {
    const result = await this.post(
      `/api/contactFlows/${contactFlow}/attributes`,
      attr,
    );
    return await result.json();
  }

  async postPrompt(prompt: PromptVm, flowId: string): Promise<PromptVm> {
    const result = await this.post(
      `/api/contactFlows/${flowId}/prompts`,
      prompt,
    );
    return await result.json();
  }

  async updateAttribute(
    attr: AttributeVm,
    contactFlow: string,
  ): Promise<AttributeVm> {
    const result = await this.put(
      `/api/contactFlows/${contactFlow}/attributes`,
      attr,
    );
    return await result.json();
  }

  async putPrompt(prompt: PromptVm, flowId: string): Promise<PromptVm> {
    const result = await this.put(
      `/api/contactFlows/${flowId}/prompts`,
      prompt,
    );
    return await result.json();
  }
  async importConfigurationSetData(data: ConfigurationSetIE[]): Promise<any> {
    const result = await this.post(
      `/api/contactFlows/import/config-set-data`,
      data,
    );
    return await result.json();
  }
}
