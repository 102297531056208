import React, {useContext, useEffect, useState } from 'react';
//import { useHistory } from "react-router-dom";
import {AppContext} from '@vf';
import {HoursApiClient} from './HoursApiClient';
import {ConnectApiClient} from './ConnectApiClient';
import {HelpersApiClient} from './HelpersApiClient';
import {HolidaysApiClient} from './HolidaysApiClient';
import {ClosuresApiClient} from './ClosuresApiClient';
import AmplifyService from '@vf/services/AmplifyService';
import Loader from '../../core/Loader';
import AppContextPropsType from 'types/AppContextPropsType';
import {ContactFlowsApiClient} from './ContactFlowsApiClient';
import {
  HelperApi,
  IConnectApi,
  HolidayApi,
  ClosureApi,
  HourApi,
  IContactFlowsApi,
} from 'types/api/api-interfaces';

export interface IApiContext {
  contactflow: IContactFlowsApi;
  closure: ClosureApi;
  connect: IConnectApi;
  helpers: HelperApi;
  holiday: HolidayApi;
  hour: HourApi;
}

export const ApiContext = React.createContext<IApiContext>(null as any);

function ApiContextProvider(props: {children: any}) {
  //let history = useHistory();
  const {config, user, updateAuthUser} = useContext<AppContextPropsType>(
    AppContext,
  );

  const [state, setState] = useState<IApiContext>(null as any);
  useEffect(() => {
    if (config) {
      const useAuth = config.environment !== 'local';
      setState({
        contactflow: new ContactFlowsApiClient(config.apiUrl, useAuth),
        closure: new ClosuresApiClient(config.apiUrl, useAuth),
        connect: new ConnectApiClient(config.apiUrl, useAuth),
        helpers: new HelpersApiClient(config.apiUrl, useAuth),
        holiday: new HolidaysApiClient(config.apiUrl, useAuth),
        hour: new HoursApiClient(config.apiUrl, useAuth),
      });
      if (!config.cognito) return;

      AmplifyService.setHubListener(updateAuthUser);
      AmplifyService.isAuthenticated().then((bool) => {
        if (bool) {
          AmplifyService.getClaims()
            .then((claims) => {
              if (!claims) {
                AmplifyService.federatedLogin();
              } else {
                if (claims.groups && claims.groups.length > 0) {
                  updateAuthUser({
                    ...claims,
                    role: claims.groups.includes('admin') ? 'admin' : 'supervisor',
                  });
                } else {
                  updateAuthUser({
                    ...claims,
                    role: null,
                  })
                  //window.location.replace(`${window.location.origin}/error-pages/error-401`)
                }
              }
            })
            .catch(err => {
              console.log(err)
              updateAuthUser(null)
            })
        } else {
          AmplifyService.federatedLogin();
        }
      });
    }
  }, [config, updateAuthUser]);

  return (
    <ApiContext.Provider value={state}>
      {user && state ? props.children : <Loader />}
    </ApiContext.Provider>
  );
}

export default ApiContextProvider;
